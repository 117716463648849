import { useEffect, useState } from 'react';

const PannelOne = ({moons}: {moons: string[]}) => (
	<div className='pannel pannel_one'>{
		moons.map((moon, i) => (
			i < moons.length - 2 &&
			<div
				style={{animationDelay: `${i * 0.1}s`}}
				key={i}
				className='full-moon' />
		))
	}</div>
);

const PannelTwo = ({moons}: {moons: string[]}) => (
	<div className='pannel pannel_two'>
		<span style={{animationDelay: '0s'}} className='text__newligne text--lg'>{moons.length - 2}</span>
		<span style={{animationDelay: '0.2s'}} className='text__newligne text--md'>moons</span>
		<span style={{animationDelay: '0.6s'}} className='text__newligne text--sm'>since June, 14 2022</span>
	</div>
);

const PannelThree = ({moons}: {moons: string[]}) => (
	<div className='pannel pannel_three'>

		<span style={{animationDelay: '0s'}} className='text__newligne text--sm'>Next full moon:</span>

		<time style={{animationDelay: '.4s'}} className='text__newligne text--md'dateTime={new Date(moons[moons.length - 1]).toISOString()}>
			{new Date(moons[moons.length - 1]).toLocaleString('default', {day: 'numeric', month: 'long'})}
		</time>

	</div>
);

const MoonData = ({moons}: {moons: string[]}) => {

	const [ pannelIndex, setPannelIndex ] = useState(0);

	const handleClick = () => {
		setPannelIndex(pannelIndex === 2 ? 0 : pannelIndex + 1);
	}

	const pannels = [
		<PannelOne		moons={moons} />,
		<PannelTwo		moons={moons} />,
		<PannelThree	moons={moons} />
	];

	return (<>

		<div className='pannel__container' onClick={handleClick}>
			{pannels[pannelIndex]}
		</div>

	</>
	);
};

const App = () => {

	const [ moons, setMoons ] = useState<string[]>([]);

	useEffect(() => {
		fetch('https://api.moon.cat-os.systems')
		.then(res => res.json())
		.then(data => {
			setMoons(data.dates);
		});
	}, []);

	return (
		<div className="App">{
			moons.length === 0 ?
				'Counting moons...'
			: <MoonData moons={moons} />
		}</div>
	);

}

export default App;